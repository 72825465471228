<template>
  <div>
    <app-breadcrumb :items="breadcrumbs" />
    <loading :show="loading.page">
      <filter-reports
          ref="filter"
          :filter.sync="filter"
          :enable="isEnableFilter"
          show-is-ag-filter
          show-seasons-filter
          @initFilter="initFilter()"
      />
      <table-report
          ref="table"
          export-csv
          pagination
          :filter="filter"
          :is-loading-page="loading.page"
          :is-loading-table.sync="loading.table"
          :is-init-filter="isInitFilter"
          default-sort-by="supplier"
      />
    </loading>
  </div>
</template>
<script>
import TableReport from "@/views/reports/components/table/index.vue";
import AppBreadcrumb from "@/layouts/components/AppBreadcrumb.vue";
import FilterReports from "@/views/reports/components/FilterReports.vue";

export default {
  components: {
    FilterReports,
    AppBreadcrumb,
    TableReport,
  },
  data() {
    return {
      filter: null,
      enableFilter: false,
      loading: {
        page: false,
        table: false,
      },
      isInitFilter: false,
    }
  },
  computed: {
    isEnableFilter() {
      return !this.loading.page && !this.loading.table
    },
    breadcrumbs() {
      return [
        {
          text: 'reports.names.brand-overview',
          active: false,
        }
      ]
    },
  },
  watch: {
    loading: {
      handler() {
        if (!this.loading.table) {
          this.loading.page = false
        }
      },
      deep: true,
    }
  },
  methods: {
    initFilter() {
      this.isInitFilter = true;
      this.$refs.table.refresh()
    }
  }
}

</script>
